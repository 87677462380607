<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="columns is-multiline is-mobile is-vcentered" style="width: 100%">
          <div class="column is-10">
            <p class="modal-card-title">
              {{ modeEdit ? 'EDITAR VARIABLE DEL PRODUCTO' : 'AGREGAR VARIABLE AL PRODUCTO' }}
            </p>
          </div>
          <div class="column is-2 has-text-right">
            <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
          </div>
        </div>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="field control">
              <label class="label">Nombre del producto <span class="text_green">*</span></label>
              <input
                class="input"
                type="text"
                placeholder="Nombre del producto"
                v-model="nameProductLocal"
                name="nameProductLocal"
                v-validate="'required'"
                data-vv-as="del nombre del producto"
              >
              <span v-show="vverrors.has('nameProductLocal')" class="help is-danger">{{ vverrors.first('nameProductLocal') }}</span>
            </div>
          </div>
          <div class="column is-12">
            <div class="field control">
              <label class="label">Descripción <span class="text_green">*</span></label>
              <textarea
                class="textarea"
                placeholder="Descripción"
                v-model="descriptionLocal"
                name="descriptionLocal"
                v-validate="'required'"
                data-vv-as="de la descripción"
              />
              <span v-show="vverrors.has('descriptionLocal')" class="help is-danger">{{ vverrors.first('descriptionLocal') }}</span>
            </div>
          </div>
          <div class="column is-4">
            <div class="field control">
              <label class="label">Catálogo <span class="text_green">*</span></label>
              <input
                class="input"
                type="text"
                placeholder="Catálogo"
                v-model="catalogueLocal"
                name="catalogueLocal"
                v-validate="'required'"
                data-vv-as="del catálogo"
              >
              <span v-show="vverrors.has('catalogueLocal')" class="help is-danger">{{ vverrors.first('catalogueLocal') }}</span>
            </div>
          </div>
          <div class="column is-4">
            <div class="field control">
              <label class="label">Disponibilidad <span class="text_green">*</span></label>
              <input
                class="input"
                type="text"
                placeholder="Invetario"
                v-model="inventoryLocal"
                name="inventoryLocal"
                v-validate="'required'"
                data-vv-as="de la disponibilidad"
              >
              <span v-show="vverrors.has('inventoryLocal')" class="help is-danger">{{ vverrors.first('inventoryLocal') }}</span>
            </div>
          </div>
          <div class="column is-4">
            <div class="field control">
              <label class="label">Clave MI</label>
              <input
                class="input"
                type="text"
                placeholder="Clave MI"
                v-model="keyMILocal"
                name="keyMILocal"
                data-vv-as="de la Clave MI"
              >
              <span v-show="vverrors.has('keyMILocal')" class="help is-danger">{{ vverrors.first('keyMILocal') }}</span>
            </div>
          </div>
          <template v-if="enabledRowHeadersLocal.length > isEmpty">
            <div class="column is-4" v-for="(inputEnabledRow, index) in enabledRowHeadersLocal" :key="index">
              <div class="field control">
                <label class="label">{{ lodash.capitalize(inputEnabledRow.name) }}</label>
                <template v-if="inputEnabledRow.name !== 'MONEDA'">
                  <input
                    class="input"
                    type="text"
                    :placeholder="lodash.capitalize(inputEnabledRow.name)"
                    v-model="enabledRowHeadersLocal[index].value"
                    v-validate="'required'"
                    :name="lodash.lowerCase(inputEnabledRow.name)"
                    :maxlength="inputEnabledRow.name === 'DESCUENTO' ? 2 : ''"
                    @keypress="inputEnabledRow.name === 'DESCUENTO' || inputEnabledRow.name ===  'COSTO' || inputEnabledRow.name ===  'CANTIDAD' ? onlyNumbers() : () => {}"
                  >
                  <span v-show="vverrors.has(lodash.lowerCase(inputEnabledRow.name))" class="help is-danger">{{ vverrors.first(lodash.lowerCase(inputEnabledRow.name)) }}</span>
                </template>
                <template v-else>
                  <div class="select slct is-fullwidth">
                    <select
                      name="currencyId"
                      v-model="enabledRowHeadersLocal[index].value"
                      v-validate="'required'"
                      data-vv-as="de la moneda"
                    >
                      <option selected disabled :value="null">Moneda</option>
                      <option v-for="currency in currencyList" :key="currency.id" :value="currency.id">
                        {{ currency.code }}
                      </option>
                    </select>
                    <span v-show="vverrors.has('currencyId')" class="help is-danger">{{ vverrors.first('currencyId') }}</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          CANCELAR
        </button>
        <button
          class="button button_micro_dark"
          @click="actionModal()"
          :class="{ 'is-loading' : loading }"
          :disabled="loading"
        >
          {{ modeEdit ? 'ACTUALIZAR' : 'AGREGAR'}}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ModalRowInformationToOrder',
  data () {
    return {
      nameProductLocal: null,
      descriptionLocal: null,
      catalogueLocal: null,
      inventoryLocal: null,
      keyMILocal: null,
      enabledRowHeadersLocal: _.cloneDeep(this.enabledRowHeaders),
      loading: false,
      isEmpty: 0,
      lodash: _
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    idTable: {
      type: Number,
      default: null
    },
    indexRowTable: {
      type: Number,
      default: null
    },
    nameProduct: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    catalogue: {
      type: String,
      default: null
    },
    inventory: {
      type: String,
      default: null
    },
    keyMI: {
      type: String,
      default: null
    },
    currencyList: {
      type: Array,
      default: () => []
    },
    enabledRowHeaders: {
      type: Array,
      default: () => []
    },
    rowHeadersCurrent: {
      type: Object,
      default: null
    },
    modeEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onlyNumbers (evt) {
      evt = (!evt) ? window.event : evt
      const charCode = (evt.which) ? evt.which : evt.keyCode
      // if (charCode < 48 || charCode > 57) {
      if (charCode < 46 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async actionModal () {
      if (await this.$validator.validateAll()) {
        const idTable = this.idTable
        const indexRowTable = this.indexRowTable
        const dataRow = {
          nameProduct: this.nameProductLocal,
          description: this.descriptionLocal,
          catalogue: this.catalogueLocal,
          inventory: this.inventoryLocal,
          keyMI: this.keyMILocal,
          optionalRow: this.enabledRowHeadersLocal
        }
        this.$emit('set-data-add-row-table', dataRow, idTable, indexRowTable)
      }
    }
  },
  beforeMount () {
    if (this.modeEdit) {
      this.nameProductLocal = this.rowHeadersCurrent.nameProduct
      this.descriptionLocal = this.rowHeadersCurrent.description
      this.catalogueLocal = this.rowHeadersCurrent.catalogue
      this.inventoryLocal = this.rowHeadersCurrent.inventory
      this.keyMILocal = this.rowHeadersCurrent.keyMI
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }
</style>
